.account{
    margin-top: 120px;

    h1{
        font-size: 40px;
        font-family: din-bold;
        letter-spacing: 2;
        color: #ee1c25;
        line-height: 1.2;
    }

    p{
        color: #585a5c;
        font-family: din-bold;
        font-size: 25px;
        letter-spacing: 1px;
    }

    .formDiv{
        margin-top: 30px;

        label{
            color: #585a5c;
            font-family: din-bold;
            font-size: 18px;
            letter-spacing: 1px;
        }

        input{
            width: 500px;
            height: 50px;
            border-radius: 30px;
            border-width: 1px;
            padding: 5px;
            margin-top: 5px;
        }

        .fields{
            margin: 10px;
        }
}

    .submitButton{
        margin-left: auto;
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 150px;
        height: 30px;
        background-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        p{
            color: white;
            font-size: 18px;
            font-family: din-bold;
        }
    }

    .checkboxDiv{
        label{
            margin-left: 10px;
            font-size: 16px;
            color: #585a5c;
            font-family: din-semibold;
        }

        input{
            margin-top: 5px;
            width: 1.3em;
            height: 1.3em;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #585a5c;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
        }

        input:checked {
            background-color: gray;
        }
    }

    .disclamer{
        font-size: 18px;
        font-family: din-regular;
        color: #585a5c;
    }

    .terms{
        font-size: 18px;
        font-family: din-bold;
        color: #585a5c;
    }
}



@media(max-width: 1140px){
    .account{
        .formDiv{
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input{
                width: 500px !important;
            }
        }

        .disclamer{
            text-align: center;
        }
    }
}

@media(max-width: 575px){
    .account{
        h1{
            font-size: 35px;
        }

        .h1P{
            font-size: 20px;
        }

        .formDiv{
            input{
                width: 300px !important;
            }
        }

        .submitButton{
            width: 200px;
            margin:0 auto;
        }
    }
}

.modalOpen{
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;

    .btn-close{
        position: absolute;
        margin-bottom: 40px;
        margin-left: 250px;
    }
}

.modal-content{
    width: 450px;
    height: 300px;
}

.modal-header{
    border: none !important;
}

.modal-body{
    margin-top: 50px !important;
    color: red;
    font-family: din-bold;
    font-size: 30px;

    ul > li {
        font-size: 15px!important;
        line-height: 25px!important;
    }
}

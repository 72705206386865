footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 112px;
    background-color: #ffffff;
    position: relative;
    bottom: 0;

    img{
        width: 100%;
        max-width: 150px;
        margin: 13px;
    }

    div{
        padding-top: 60px;
        padding-bottom: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.auth{
    height: 100%;
}

.header{
    background-image: url('../../../assets/images/Banner_Sem_o_Logo.png');
    background-size: cover;
    background-position: center;
    max-width: 100%;
    height: 350px;
}

.login{
    margin-top: 76px;

    .h1Auth{
        font-size: 40px;
        font-family: din-bold;
        letter-spacing: 2;
        color: #ee1c25;
        line-height: 1.2;
    }

    .h2Auth{
        font-size: 30px;
        font-family: din-bold;
        color: #57595b;
    }

    input{
        border-width: 2px;
        border-radius: 30px;
        border: 1px solid #86898C;
        padding: 5px 15px;
        height: 40px;
        width: 100%;
    }

    label{
        font-family: din-bold;
        font-size: 20px;
        color:#ee1c25;
    }

    a{
        color: #8f8b8b;
        font-family: din-bold;
    }

    .warning{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 300px;
        height: 60px;
        background-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        margin-top: 3rem;
        p{
            color: white;
            font-size: 16px;
            font-family: din-bold;
        }
    }

    .button{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 160px;
        height: 30px;
        background-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        p{
            color: white;
            font-size: 16px;
            font-family: din-bold;
        }
    }

    .buttonRecover{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 160px;
        height: 30px;
        background-color: #fff;
        border: 1px solid #ee1c25!important;
        border-radius: 30px;
        padding: 10px;
        p{
            color: #ee1c25;
            font-size: 14px;
            font-family: din-bold;
        }
    }

    .button:hover{
        opacity: 0.5;
    }

    .colAct{
        width: 100%;
        margin-top: 10px;
    }

    .colLine{
        margin-bottom: 15px;
    }
}

.containerDefault{
    max-width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 960px;
}

@media(max-width: 992px){
    .form{
        margin-top: 50px;
        width: 425px;
    }

    .warning{
        margin-top: 1rem !important;
    }
}

@media(max-width: 768px){
    .header{
        background-image: url('../../../assets/images/Banner_Mobile.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: unset;
        height: 0;
        padding-top: 65%; /* (img-height / img-width * container-width) */
                /* (853 / 1280 * 100) */
    }

    .login{
        margin-top: -76px;
    }
}

@media(max-width: 575px){
    .h1Auth{
        font-size: 35px !important;
    }

    .login{
        margin-top: -30px;
    }
}

@media(max-width: 425px){
    .h1Auth{
        font-size: 30px !important;
    }

    .login{
        margin-top: -30px;
    }
}

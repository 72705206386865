nav{
    margin-bottom: 0;
}

.header{
    margin-top: 120px;
}

.containerDefault{
    max-width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-top: 10px;

    p > a {
        color: #fff;
        text-decoration: none;
    }
}

.navBar{
    img{
        margin: 10px;
    }

    .img1{
        width: 120px;
    }

    .img2{
        width: 60px;
    }

    .img3{
        width: 120px;
    }

    .sair{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 150px;
        height: 30px;
        background-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        p{
            color: white;
            font-size: 16px;
            font-family: din-bold;
        }
    }
}


.zuccolo{
    margin-top: 100px;

    h1{
        font-size: 40px;
        font-family: din-bold;
        letter-spacing: 2;
        color: #ee1c25;
        line-height: 1.2;
    }

    p{
        margin-top: 30px;
        color: #585a5c;
        font-family: din-bold;
        font-size: 25px;
    }

    .confira{
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 290px;
        height: 58px;
        background-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        margin-top: 30px;
        margin-left: 100px;
        p{
            margin-top: 0;
            color: white;
            font-size: 20px;
            font-family: din-bold;
        }
    }
}

.videos{
    background-color: #ee1c25;

    h2{
        font-size: 40px;
        font-family: din-bold;
        letter-spacing: 2;
        color: #ffffff;
        line-height: 1.2;
    }

    p{
        font-size: 20px;
        font-family: din-bold;
        letter-spacing: 2;
        color: #ffffff;
        line-height: 1.2;
    }

    img{
        width: 620px;
    }

    .text2{
        margin-left: 50px;
    }

    .videosMarginTop{
        margin-top: 80px !important;
    }

    .gatos{
        margin-bottom: 70px;
        margin-top: 110px;

        img{
            width: 60px;
        }

        hr{
            border: 1px solid #ffffff;
            width: 100px;
            opacity: 1;
        }
    }

    .caes{
        margin-bottom: 70px;
        margin-top: -20px;

        img{
            width: 60px;
        }

        hr{
            border: 1px solid #ffffff;
            width: 100px;
            opacity: 1;
        }
    }

    .acabamento{
        width: 100%;
    }


}

.acabamento{
    width: 100%;
}

@media(max-width: 1140px){
    .navBar{
        flex-direction:column !important;
    }

    .zuccolo{
        width: 500px;
        div:first-child{
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img{
                margin-top: 30px;
            }
        }
    }
}

@media(max-width: 992px){
    .video{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .text1{
            margin-top: 50px;
        }
    }

    .videosMarginTop{
        flex-direction: column-reverse;
        text-align: center;

        .text2{
            margin-top: 50px;
            margin-left: 0px !important;
        }
    }


}

@media(max-width: 500px){
    .navBar{
        flex-direction:column !important;
        .img1{
            width: 80px;
        }
        .img2{
            width: 50px;
        }
        .img3{
            width: 80px;
        }

        .sair{
            width: 100px;
        }
    }

    .zuccolo{
        width: 320px;
        div:first-child{
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img{
                margin-top: 30px;
            }
        }

        h1{
            font-size: 25px !important;
        }

        p{
            font-size: 18px;
        }

        .confira{
            width: 150px;
            height: 20px;
            margin-left: 75px;
            p{
                font-size: 15px;
            }
        }
    }

    .videos{
        h2{
            font-size: 30px;
        }

        p{
            font-size: 15px;
        }
    }
}

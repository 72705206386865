nav{
    margin-bottom: 0;
}

.sairMobile{
    display: none;
}

.fluid{
    background-color: #ffffff;
    position: fixed;
    top: 0;
    z-index: 999;
    height: 120px;
    box-shadow: 3px 3px 5px 6px #ccc;
}

.containerDefault{
    max-width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
    margin-top: 10px;
}

.navBar{
    img{
        margin: 10px;
    }

    .img1{
        width: 120px;
    }

    .imgLogo{
        width: 160px;
    }

    .img2{
        width: 60px;
    }

    .img3{
        width: 120px;
    }

    .sair{
        display: flex;
        justify-content: center;
        align-items:flex-start;
        flex-direction: column;
        width: 230px;
        height: 60px;
        border-width: 2px;
        border-style: solid;
        border-color: #ee1c25;
        border-radius: 30px;
        padding: 20px;
        p{
            color: #585a5c;
            font-size: 16px;
            font-family: din-semiBold;
            margin-left: 50px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }


    a{
        text-decoration: none;
        color: #585a5c;
        font-family: din-semiBold;
        margin: 10px;
    }
}


@media(max-width: 768px){
    .sairMobile{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .botao{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        background-color: #ee1c25;
        width: 115px;
        height: 30px;
        border-radius: 30px;
        font-family: din-bold;
        color: white;
        cursor: pointer;
    }

    .navBar{
        flex-direction:column !important;
    }

    .fluid{
        height: 180px;
    }

    .sair{
        display: none !important;
    }
}

@media(max-width: 500px){
    .navBar{
        flex-direction:column !important;
        .img1{
            width: 80px;
        }
        .img2{
            width: 50px;
        }
        .img3{
            width: 80px;
        }

        img{
            margin: 5px !important;
        }
    }

    .fluid{
        height: 150px;
    }
}

@import './resets';

@font-face {
    font-family: din-bold;
    src: url('../../assets/fonts/din-bold.otf');
}

@font-face {
    font-family: din-regular;
    src: url('../../assets/fonts/din-regular.otf');
}

@font-face {
    font-family: din-extrabold;
    src: url('../../assets/fonts/din-extrabold.otf');
}

@font-face {
    font-family: din-heavy;
    src: url('../../assets/fonts/din-heavy.otf');
}

@font-face {
    font-family: din-medium;
    src: url('../../assets/fonts/din-medium.otf');
}

@font-face {
    font-family: din-semibold;
    src: url('../../assets/fonts/din-semibold.otf');
}
